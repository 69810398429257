<template>
  <v-stepper
    flat
    no-gutters
    vertical
    v-model="e6"
    style="height: 100%; padding-left: 13px; padding-right: 13px"
  >
    <v-stepper-header style="margin-bottom: 10px">
      <v-stepper-step
        class="px-2"
        :complete="e6 > 1"
        step="1"
        :class="e6 != 1 ? 'd-none' : ''"
        :rules="[() => valid1]"
      >
        {{ $t("generalInformation") }}
        <small v-if="!valid1">
          {{ $t("SomeFieldsIsRequired") }}
        </small>
      </v-stepper-step>

      <v-stepper-step
        class="px-2"
        :complete="e6 > 2"
        step="2"
        :class="e6 != 2 ? 'd-none' : ''"
        :rules="[() => valid2]"
      >
        {{ $t("personalInformation") }}
        <small v-if="!valid2">
          {{ $t("SomeFieldsIsRequired") }}
        </small>
      </v-stepper-step>

      <v-stepper-step
        class="px-2"
        :complete="e6 > 3"
        step="3"
        :class="e6 != 3 ? 'd-none' : ''"
        :rules="[() => valid3]"
      >
        {{ $t("doctorInformation") }}
        <small v-if="!valid3">
          {{ $t("SomeFieldsIsRequired") }}
        </small>
      </v-stepper-step>

      <v-stepper-step
        class="px-2"
        step="4"
        :class="e6 != 4 ? 'd-none' : ''"
        :rules="[() => valid4]"
      >
        {{ $t("login.register") }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-content class="ma-0 pa-0" step="1">
      <v-form ref="form1" v-model="valid1" lazy-validation>
        <!-- <v-row>
            <v-col cols="5">
              .test.kf.iq
            </v-col>
            <v-col> -->

        <v-text-field
          :label="$t('login.hostName')"
          v-model="register.hostName"
          :rules="[
            rules.required,
            validHostName || $t('error.invalidHostName'),
            rules.hostName,
          ]"
          suffix=".test.kf.iq"
          prefix="https://"
          ref="hostName"
          @keyup="hostNameKeyup"
          filled
          dense
          placeholder=" "
          persistent-placeholder
          style="direction: ltr"
        />
        <!-- </v-col>
            @keypress="hostNameChange"

          </v-row> -->

        <v-autocomplete
          :label="$t('login.clientType')"
          v-model="register.clientTypes"
          multiple
          chips
          :items="clientTypes"
          ref="clientType"
          :rules="[
            (value) => !!value || value == 0 || $t('ThisFieldIsRequired'),
          ]"
          filled
          dense
          placeholder=" "
          persistent-placeholder
        />
        <v-text-field
          :label="$t('settings.branchName')"
          v-model="register.branchName"
          ref="branchName"
          :rules="[rules.required]"
          filled
          dense
          placeholder=" "
          persistent-placeholder
        />
        <v-row style="position: relative" no-gutters class="mt-5">
          <v-col>
            <v-file-input
              :rules="[
                (value) =>
                  !value || value.size < 2000000 || $t('error.FileIsTooBig'),
              ]"
              accept="image/png, image/jpeg, image/bmp"
              @change="Preview_image"
              v-model="register.logo"
              :label="$t('settings.branchLogoUrl')"
              filled
              dense
            >
            </v-file-input>
          </v-col>
          <v-col cols="auto" style="width: 91px" class="mx-2">
            <v-card
              class="pa-1"
              style="position: absolute; top: -20px; z-index: 1"
              min-width="90"
              min-height="90"
            >
              <v-img
                max-width="90"
                contain
                max-height="80"
                :src="register.logoUrl"
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
      <v-btn color="primary" @click="move(2)">
        {{ $t("continue") }}
      </v-btn>
    </v-stepper-content>

    <v-stepper-content class="ma-0 pa-0" step="2">
      <v-form ref="form2" v-model="valid2" lazy-validation>
        <v-row class="mt-1">
          <v-col cols="12" md="6" lg="6">
            <v-text-field
              placeholder=" "
              persistent-placeholder
              :label="$t('email')"
              v-model="register.email"
              :rules="[rules.required, rules.email]"
              filled
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class=""
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'mt-n4'
                : $vuetify.rtl
                ? 'pr-1 '
                : 'pl-1'
            "
          >
            <v-text-field
              placeholder=" "
              persistent-placeholder
              :label="$t('phone')"
              v-model="register.phoneNumber"
              counter="phoneNumberCounter"
              :rules="[
                rules.phone,
                (value) => !!value || $t('ThisFieldIsRequired'),
              ]"
              filled
              dense
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" lg="6" class="mt-n4">
            <v-text-field
              placeholder=" "
              persistent-placeholder
              :label="$t('login.password')"
              v-model="register.password"
              :rules="[rules.required, rules.min, rules.password]"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              filled
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="6"
            class="mt-n4"
            :class="
              $vuetify.breakpoint.smAndDown
                ? ''
                : $vuetify.rtl
                ? 'pr-1'
                : 'pl-1'
            "
          >
            <v-text-field
              placeholder=" "
              persistent-placeholder
              type="password"
              :label="$t('users.confirmPassword')"
              v-model="register.confirmPassword"
              :rules="[rules.required, rules.confirmPassword]"
              filled
              dense
            />
          </v-col>
          <v-col cols="12" class="mt-n3">
            <v-text-field
              placeholder=" "
              persistent-placeholder
              :label="$t('address')"
              v-model="register.branchAddress"
              filled
              dense
            />
          </v-col>
        </v-row>

        <v-btn color="primary" @click="move(3)">
          {{ $t("continue") }}
        </v-btn>
        <v-btn text @click="e6 = 1">
          {{ $t("back") }}
        </v-btn>
      </v-form>
    </v-stepper-content>

    <v-stepper-content
      class="ma-0 pa-0"
      v-if="register.clientType != 2"
      step="3"
    >
      <v-form ref="form3" v-model="valid3" lazy-validation>
        <v-switch
          class="mx-3"
          v-model="register.isDoctor"
          label="   إنشاء طبيب ؟"
        ></v-switch>
        <v-row v-if="register.isDoctor">
          <v-col class="py-0" cols="12" md="4" lg="4">
            <v-text-field
              placeholder=" "
              persistent-placeholder
              :label="$t('doctors.doctorFirstName')"
              v-model="register.doctorFirstName"
              :rules="[(value) => !!value || $t('ThisFieldIsRequired')]"
              filled
              dense
            />
          </v-col>
          <v-col class="py-0 px-1" cols="12" md="4" lg="4">
            <v-text-field
              placeholder=""
              persistent-placeholder
              :label="$t('doctors.doctorMiddleName')"
              v-model="register.doctorMiddleName"
              filled
              dense
            />
          </v-col>
          <v-col class="py-0" cols="12" md="4" lg="4">
            <v-text-field
              placeholder=" "
              persistent-placeholder
              :label="$t('doctors.doctorLastName')"
              v-model="register.doctorLastName"
              filled
              dense
            />
          </v-col>

          <v-col class="py-0" cols="12" md="6" lg="6">
            <v-text-field
              placeholder=" "
              persistent-placeholder
              :label="$t('doctors.doctorDegree')"
              v-model="register.doctorDegree"
              filled
              dense
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="py-0"
            :class="$vuetify.rtl ? 'pr-1' : 'pl-1'"
          >
            <v-text-field
              placeholder=" "
              persistent-placeholder
              :label="$t('doctors.doctorSpecialization')"
              v-model="register.doctorSpecialization"
              filled
              dense
            />
          </v-col>
        </v-row>
      </v-form>

      <v-btn color="primary" @click="move(4)">
        {{ $t("continue") }}
      </v-btn>
      <v-btn text @click="e6 = 2">
        {{ $t("back") }}
      </v-btn>
    </v-stepper-content>

    <v-stepper-content class="ma-0 pa-0" step="4">
      <v-form ref="form4" v-model="valid4" lazy-validation>
        <v-row no-gutters class="mt-3">
          <v-col cols="auto" class="pt-1">
            <v-btn icon color="primary white--text" @click="getCaptcha">
              <v-icon> mdi-refresh-circle </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="pt-1 mx-2">
            <v-img
              width="100"
              height="33"
              :src="'data:image/jpeg;base64,' + image"
            ></v-img>
          </v-col>

          <v-col align-self="center" cols="cols" md="6" sm="12">
            <v-otp-input
              @change="captchaKeyUp"
              class="white--color"
              length="4"
              type="number"
              v-model="register.captchaCode"
              :rules="[rules.required, validCaptcha]"
              style="direction: ltr; color: #fff"
              plain
            ></v-otp-input>
            <!-- <v-text-field
                placeholder=""
                persistent-placeholder
                :label="$t('captcha')"
                v-model="register.captchaCode"
                counter="4"
                @keyup="captchaKeyUp"
                :rules="[rules.required, validCaptcha]"
                prepend-icon="mdi-rotate-3d-variant"
                @click:prepend="getCaptcha"
                filled
                hide-details
                dense
              /> -->
          </v-col>
        </v-row>
      </v-form>
      <v-btn
        color="primary"
        @click="registerPost"
        :loading="loading"
        class="mt-3"
      >
        {{ $t("login.register") }}
      </v-btn>

      <v-btn text class="mt-3" @click="e6 = register.clientType == 2 ? 2 : 3">
        {{ $t("back") }}
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
export default {
  props: ["register", "appUrl"],
  mounted() {},
  data() {
    return {
      e6: 1,
      show1: null,
      myfalse: false,
      image: null,
      siteKey: "6Le1PHEgAAAAAHVRPwmaGTjTQSBCs6MhYeGP7MV3",
      valid1: true,
      valid2: true,
      valid3: true,
      valid4: true,
      loading: false,
      validHostName: true,
      validCaptcha: true,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        password: (v) =>
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(v) ||
          this.$t("passwordIsNotValid"),

        confirmPassword: () =>
          this.register.confirmPassword === this.register.password ||
          this.$t("users.notMatchPassword"),
        email: (v) => /.+@.+\..+/.test(v) || this.$t("users.validEmail"),
        min: (v) =>
          (v != null && v.length >= 6) ||
          this.$t("login.PasswordMustContain6OrMoreElements"),
        phone: (v) =>
          /^[0-9]+$/.test(v) ||
          /^\+[0-9]+$/.test(v) ||
          this.$t("validPhoneNumber"),
        hostName: (v) =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$/.test(v) ||
          this.$t("error.invalidHostName"),
      },
    };
  },
  computed: {
    clientTypes() {
      return [
        { text: this.$t("services.serviceGroupType.general"), value: 0 },
        { text: this.$t("services.serviceGroupType.dental"), value: 1 },
        { text: this.$t("tests.testsLab"), value: 2 },
      ];
    },
    phoneNumberCounter() {
      return this.register.phoneNumber.length <= 11 ? 11 : 14;
    },
  },
  created() {
    this.getCaptcha();
  },
  methods: {
    captchaKeyUp() {
      if (!this.validCaptcha) {
        this.validCaptcha = true;
      }
    },
    getCaptcha() {
      this.register.captchaCode = null;

      let postConfig = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        //responseType: "blob",
      };
      this.$axios
        .get(
          process.env.VUE_APP_CLIENT_API_URL + "Client/GetCaptchaImage",
          postConfig
        )
        .then((response) => {
          var x = response.data;
          this.register.captchaKey = x.captchaKey;
          this.image = x.image;
          // let reader = new FileReader();
          // reader.readAsDataURL(response.data.image);
          // reader.onload = () => {
          //   this.image = reader.result;
          // };

          //this.image = URL.createObjectURL(response.data);
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
          this.loading = false;
        });
    },

    registerPost() {
      this.loading = true;
      if (this.valid1 && this.valid2 && this.valid3 && this.valid4) {
        this.register.userName = this.register.email;

        var data = new FormData();
        for (var key in this.register) {
          if (this.register[key] && key != "clientTypes")
            data.append(key, this.register[key]);
        }

        for (let index = 0; index < this.register.clientTypes.length; index++) {
          const element = this.register.clientTypes[index];
          data.append("clientTypes", element);
        }

        //console.log(this.register);
        //console.log(data);

        this.$axios
          .post(process.env.VUE_APP_CLIENT_API_URL + "Client/Register", data)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
              window.location.href = process.env.VUE_APP_URL.replace(
                "{0}",
                this.register.hostName
              );
            } else {
              if (response.data.message == "InvalidCaptcha") {
                this.validCaptcha = false;
                this.getCaptcha();
              }
              this.$toast.error(this.$t("error." + response.data.message));
              this.loading = false;
            }
            // console.log(data);
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    hostNameKeyup() {
      if (this.register.hostName) {
        this.$axios
          .get(
            process.env.VUE_APP_CLIENT_API_URL +
              "Client/CheckHostName?host=" +
              this.register.hostName
          )
          .then((response) => {
            //console.log(response);

            if (response.data.status == "Error") {
              this.validHostName = false;
              //console.log("Error");
            } else {
              this.validHostName = true;
              //console.log("Successful");
            }
          })
          .catch((ex) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(ex);
          });
      }
    },
    hostNameChange(e) {
      console.log(e);

      if (/^[A-za-z0-9]+$/.test(e.key)) {
        return true;
      } else e.preventDefault();
    },
    move(step) {
      var form = "form" + (step - 1);
      var valid = this.$refs[form].validate();
      if (valid) {
        if (this.register.clientType == 2 && step == 3) {
          this.e6 = step + 1;
        } else {
          this.e6 = step;
        }
      }
    },
    Preview_image() {
      if (this.register.logo != null && this.register.logo != "")
        this.register.logoUrl = URL.createObjectURL(this.register.logo);
    },
  },
};
</script>

<style scoped>
.v-stepper {
  background-color: transparent !important;
}

.v-stepper__content {
  border-left: none !important;
  border-right: none !important;
}
</style>
