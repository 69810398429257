<template>
  <v-container
    class="pa-0"
    style="max-width: 100%; overflow: hidden"
    fill-height
    tag="section"
  >
    <settings
      style="position: fixed; top: 0; z-index: 1"
      :style="rtl ? 'left:0' : 'right:0'"
    />
    <!-- <v-slide-y-transition appear> -->
    <v-row class="px-2" style="height: 100%">
      <v-col
        cols="12"
        md="6"
        lg="6"
        class="pa-0 loginBackground"
        :class="
          $vuetify.breakpoint.sm || $vuetify.breakpoint.xsOnly
            ? 'fullwidth'
            : '' + rtl
            ? 'loginBackgroundAR'
            : 'loginBackground'
        "
        :style="
          $vuetify.theme.isDark
            ? '    background-color:  #182e32;'
            : 'background-color: white;'
        "
      >
        <div
          min-width="100%"
          :style="$vuetify.breakpoint.smAndUp ? 'margin-top:10%' : ''"
        >
          <v-row
            no-gutters
            height="100%"
            style="min-height: 90%; height: 90%; justify-content: center"
            class=""
          >
            <v-col
              align-self="center"
              style="max-width: 550px; min-width: 350px"
            >
              <div dir="" class="text-center" style="width: 100%">
                <v-row no-gutters>
                  <v-col align-self="center">
                    <v-img contain :src="logo" width="150" />
                  </v-col>

                  <v-col cols="auto" align-self="center">
                    <v-img
                      width="100"
                      class=""
                      contain
                      v-if="branch && !isRegisterAppUrl"
                      :src="branch.logoUrl"
                    ></v-img>
                  </v-col>
                </v-row>
              </div>

              <!-- <v-tabs-items v-model="tab"> -->
              <div class="px-10" v-if="!isRegisterAppUrl">
                <v-form v-on:submit.prevent="login">
                  <!-- <div class="text-center grey--text body-1 font-weight-light">UIMS</div> -->
                  <v-text-field
                    :label="$t('email')"
                    v-model="username"
                    :rules="[(value) => !!value || $t('ThisFieldIsRequired')]"
                    placeholder=" "
                    filled
                    persistent-placeholder
                    :name="getId"
                    append-icon="mdi-email"
                  />
                  <v-text-field
                    placeholder=" "
                    persistent-placeholder
                    :label="$t('login.password')"
                    v-model="password"
                    filled
                    :rules="[(value) => !!value || $t('ThisFieldIsRequired')]"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1 = !show1"
                    :name="getId"
                  />
                  <v-col class="text-center">
                    <v-btn
                      :loading="loading"
                      @click.prevent="login()"
                      type="submit"
                      large
                      block
                      color="primary darken-1"
                      ref="sendReply"
                    >
                      {{ $t("login.signIn") }}
                    </v-btn>
                    <br />

                    <div v-if="branch">
                      <span style="font-size: 22px">
                        {{ branch.branchName }}
                      </span>
                      <span v-if="branch.branchAddress">
                        <br />
                        {{ branch.branchAddress }}
                      </span>
                      <br />
                      {{ branch.branchNumber }}
                    </div>
                  </v-col>
                </v-form>
              </div>

              <div v-if="isRegisterAppUrl">
                <register :register="register" />
              </div>
              <!-- </v-tabs-items> -->
            </v-col>
          </v-row>
          <!-- <v-footer dark padless absolute>
              <v-card class="flex" flat tile>
                <v-card-title class="grey darken-3">
                  <strong class="subheading">
                    <a text href="https://kf.iq" target="_blank">
                      <v-img
                        width="200"
                        src="../../assets/Kafel-logo.svg"
                        lazy-src="../../assets/Kafel-logo.svg"
                        style="width: 200px; height: 52px"
                      >
                      </v-img>
                    </a>
                  </strong>
                  <v-spacer></v-spacer>

                  <v-btn
                    v-for="icon in icons"
                    :key="icon.icon"
                    :href="icon.link"
                    target="_blank"
                    class="mx-4"
                    dark
                    icon
                  >
                    <v-icon size="24px">
                      {{ icon.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>
            </v-footer> -->
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="6"
        align-self="center"
        class="hidden-xs-and-up hidden-sm-and-down"
      >
        <!-- <v-img src="../../assets/login.jpg"></v-img> -->
      </v-col>
    </v-row>
    <!-- </v-slide-y-transition> -->
    <!-- <div id="particles-js"></div> -->
  </v-container>
</template>

<script>
import Settings from "../../components/Settings.vue";
import Register from "../../components/Register.vue";

import { v4 } from "uuid";

export default {
  components: { Settings, Register },
  data() {
    return {
      show1: false,
      tab: 0,
      register: {},
      logo: require("../../assets/newlogo.svg"),
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        confirmPassword: () =>
          this.register.confirmPassword === this.register.password ||
          this.$t("users.notMatchPassword"),
        email: (v) => /.+@.+\..+/.test(v) || this.$t("users.validEmail"),
        min: (v) =>
          v.length >= 6 || this.$t("login.PasswordMustContain6OrMoreElements"),
      },
      username: "",
      password: "",
      loading: false,
      dark: localStorage.dark === "true",
      icons: [
        {
          icon: "mdi-instagram",
          link: "https://instagram.com/alkafeel.center/",
        },
        {
          icon: "mdi-facebook",
          link: "https://www.facebook.com/alkafeelcenter/",
        },
      ],
      branch: null,
    };
  },

  computed: {
    isRegisterAppUrl() {
      var url = window.location.host;

      if (url == process.env.VUE_APP_REGISTER_URL) {
        return true;
      } else {
        return false;
      }
    },
    rtl() {
      return this.$vuetify.rtl;
    },
  },
  mounted() {
    // this.$vuetify.rtl = this.rtl;
    // this.$vuetify.theme.dark = this.dark;
    // var lang = this.rtl ? "ar" : "en";
    // localStorage.lang = lang;
    // this.$i18n.locale = lang;
  },
  methods: {
    login() {
      if (this.username && this.password) {
        this.loading = true;
        const data = {
          UserName: this.username.replace(/ /g, ""),
          Password: this.password,
          ClientId: Math.floor(Math.random() * Date.now()),
          ClientType: "Web",
        };
        this.$axios
          .post("Account/Login", data)
          .then((res) => {
            this.loading = false;
            if (res.data.status == "Successful") {
              this.$store.dispatch("login", res.data.data);
            } else {
              this.$toast.error(
                this.$t("login.TheUsernameOrPasswordIsIncorrect")
              );
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$toast.error(
              this.$t("login.TheUsernameOrPasswordIsIncorrect")
            );
          });
      } else {
        this.$toast.error(this.$t("BeSureToEnterTheInformation"));
      }
    },

    getId() {
      //console.log(v4());
      return v4();
    },
  },
  created() {
    this.$store.dispatch("logout");

    var host = window.location.host;
    if (host != process.env.VUE_APP_REGISTER_URL) {
      var hostName = host.split(".")[0];
      this.$axios
        .get(
          process.env.VUE_APP_CLIENT_API_URL +
            "Client/CheckHostName?host=" +
            hostName
        )
        .then((res) => {
          this.loading = false;

          if (
            res.data.status != "Error" &&
            res.data.message != "HostNameExist"
          ) {
            this.$router.push({ path: "/error" });
          } else {
            this.branch = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error(this.$t("error.AnErrorOccurredDuringTheProcess"));
        });
    }
  },
};
</script>

<style scoped>
.loginBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-image: url("../../assets/newlogo.svg");
  z-index: 0;
  background-size: 990%;
  background-position: 45%;
  opacity: 0.2;
  transform: scaleX(-1);
}

.loginBackgroundAR::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-image: url("../../assets/newlogo.svg");
  z-index: 0;
  background-size: 990%;
  background-position: 45%;
  opacity: 0.2;
  transform: scaleX(1);
}
.fullwidth::before {
  width: 100%;
}
.v-tabs-items {
  background-color: transparent !important;
}
</style>
